export const EPGReducerActionType = {
  UpdateActiveDayIndex: 'updateActiveDayIndex',
  UpdateIsFetchingEPGGrid: 'updateIsFetchingEPGGrid',
  UpdateIsFetchingTimeslices: 'updateIsFetchingTimeslices',
  UpdateSelectedDayUrl: 'updateSelectedDayUrl',
} as const;

type ActionUpdateActiveDayIndex = {
  type: typeof EPGReducerActionType.UpdateActiveDayIndex;
  payload: {
    activeDayIndex: number;
  };
};

type ActionUpdateIsFetchingEPGGrid = {
  type: typeof EPGReducerActionType.UpdateIsFetchingEPGGrid;
  payload: {
    isFetchingEPGGrid: boolean;
  };
};

type ActionUpdateIsFetchingTimeslices = {
  type: typeof EPGReducerActionType.UpdateIsFetchingTimeslices;
  payload: {
    isFetchingTimeslices: boolean;
  };
};

type ActionUpdateSelectedDayUrl = {
  type: typeof EPGReducerActionType.UpdateSelectedDayUrl;
  payload: {
    selectedDayUrl: string;
  };
};

export type EPGReducerAction =
  | ActionUpdateActiveDayIndex
  | ActionUpdateIsFetchingEPGGrid
  | ActionUpdateIsFetchingTimeslices
  | ActionUpdateSelectedDayUrl;
